.contactUs {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;


    .container {
        margin-top: 40px;
        margin-bottom: 40px;
        width: 85%;
        border-radius: 12px;
        padding: 20px;
        background-image: url('../../../assets//images/callUs.png');
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 50px;

            .logo {
                width: 200px;
            }

            .emaill {
                margin-top: 20px;
                display: flex;
                color: #FFF;
                gap: 5px;

                p {
                    cursor: pointer;
                    transition: 500ms;
                    font-size: 16px;

                    &:hover {
                        color: #ccf1ff;
                    }
                }
            }

            .sections {
                margin-top: 40px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: space-around;
                /* Centers items horizontally */
                align-items: center;
                place-items: end center; 
                width: 100%;

                .section {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    h4 {
                        color: #FFF;
                        font-size: 24px;
                        font-weight: 500;
                        text-align: center;
                        margin-bottom: 5px;
                    }

                    .call {
                        display: flex;
                        color: #FFF;
                        gap: 5px;
                        align-items: center;
                        

                        p {
                            text-decoration: none !important;
                            border: none !important;
                            cursor: pointer;
                            transition: 500ms;
                            font-size: 16px;
                            &:hover {
                                color: #ccf1ff;
                            }
                        }
                    }

                    .email {
                        display: flex;
                        color: #FFF;
                        gap: 5px;

                        p {
                            cursor: pointer;
                            transition: 500ms;
                            font-size: 16px;

                            &:hover {
                                color: #ccf1ff;
                            }
                        }
                    }
                }
            }

            .section {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 5px;

                h4 {
                    color: #FFF;
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 5px;
                }

                .call {
                    display: flex;
                    color: #FFF;
                    gap: 5px;
                    align-items: center;

                    p {
                        cursor: pointer;
                        transition: 500ms;
                        font-size: 16px;

                        &:hover {
                            color: #ccf1ff;
                        }
                    }
                }

                .email {
                    display: flex;
                    color: #FFF;
                    gap: 5px;
                    font-size: 16px;

                    p {
                        cursor: pointer;
                        transition: 500ms;

                        &:hover {
                            color: #ccf1ff;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    .contactUs {
    
    
        .container {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 85%;
            border-radius: 12px;
            padding: 20px;
    
            .content {
                padding: 30px 50px;
    
                .logo {
                    width: 180px;
                }
    
                .sections {
                    margin-top: 40px;
                    grid-template-columns: repeat(1, 1fr);
    
                    .section {
                        gap: 5px;
    
                        h4 {
                            font-size: 22px;
                            font-weight: 500;
                        }
    
                        .call {
                            gap: 5px;
                            
    
                            p {
                                font-size: 16px;
                            }
                        }
    
                        .email {
    
                            p {
                                font-size: 15px;
                            }
                        }
                    }
                }
    
                .section {
    
                    h4 {
                        font-size: 22px;
                        font-weight: 500;
                        text-align: center;
                        margin-bottom: 5px;
                    }
    
                    .call {
                        display: flex;
                        color: #FFF;
                        gap: 5px;
                        align-items: center;
    
                        p {
                            cursor: pointer;
                            transition: 500ms;
                            font-size: 16px;
    
                            &:hover {
                                color: #ccf1ff;
                            }
                        }
                    }
    
                    .email {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}