.about {
    margin-top: 50px;
    padding: 15px;

    .sectionOne {
        display: flex;
        padding: 15px 100px;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        img {
            width: 250px;
            height: 250px;
        }


        p {
            text-align: right;
            font-size: 18px;
            margin-left: 20px;
        }

    }

    .sectionTow {
        display: flex;
        padding: 15px 100px;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        img {
            width: 250px;
        }


        p {
            text-align: right;
            font-size: 18px;
            margin-right: 50px;
        }

    }
}

@media screen and (max-width: 1000px){
    .about {
        margin-top: 50px;
        padding: 5px;
    
        .sectionOne {
            flex-direction: column;
            padding: 15px 10px;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
    
            img {
                order: 2;
            }
    
            p {
                order: 1;
                text-align: center;
                font-size: 18px;
                margin-bottom: 30px;
                margin-left: 0;
            }
    
        }
    
        .sectionTow {
            flex-direction: column;
            padding: 15px 10px;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            img {
                order: 2;
            }
    
            p {
                order: 1;
                text-align: center;
                font-size: 18px;
                margin-bottom: 30px;
                margin-right: 0;
            }
    
        }
    }
}