::-webkit-scrollbar{
    width: .8rem;
    height: 0.9rem;
  }
  
  ::-webkit-scrollbar-track{
    background: #FFF;
    /* border-radius: 100vw;
    margin-block: .5rem; */
  }
  
  ::-webkit-scrollbar-thumb{
    background: linear-gradient( to bottom, #0A85BA,  #358FC2);
    border-radius: 100vw;
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(to bottom, #26B0EC,  #0A85BA);
  }