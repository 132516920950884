@font-face {
    font-family: 'madani';
    src: url('/assets/fonts/madani_regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'madani';
    src: url('/assets/fonts/madani_medium.ttf') format('truetype');
    font-weight: 500; 
    font-style: normal;
}

@font-face {
    font-family: 'madani';
    src: url('/assets/fonts/madani_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}