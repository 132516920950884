.slidee {
    padding: 10px 40px;
    margin-top: 10px;

    .slideshow {
        // background-color: #d1d1d1;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 450px;
        border-radius: 30px;

        .slides-container {

            .slide {
                position: absolute;
                top: 0;
                left: 100%;
                /* Initially position slides to the right */
                width: 100%;
                height: 100%;
                background-color: #19A2D9;
                /* Background color for all slides */
                transition: left 0.5s ease-in-out;

                .slideContainer {
                    .cover {
                        display: block;
                        object-fit: cover;
                        width: 100%;
                        height: 100vh;
                        object-fit: cover;
                    }

                    .cover2{
                        display: none;
                    }

                    .blackk {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #11252ed1;
                    }

                    .slideContent{
                        position: absolute;
                        width: 95%;
                        height: 100%;
                        object-fit: cover;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        h2{
                            color: #FFF;
                            font-size: 5vw;
                            text-align: center;
                        }

                        h3{
                            display: none;
                        }

                        p{
                            color: #FFF;
                            font-size: 2vw;
                            text-align: center;
                        }
                        button{
                            border: none;
                            padding: 10px 30px;
                            font-size: 1.5vw;
                            font-weight: 500;
                            border-radius: 8px;
                            background-color: #19A2D9;
                            color: #FFF;
                            cursor: pointer;
                            margin-top: 30px;

                            &:hover{
                                background-color: #146f93;
                            }
                        }
                    }
                }


            }

            .slide.active {
                display: block;
                left: 0;
            }
        }
    }

    .circle-indicators {
        text-align: center;
        margin-top: 8px;

        .circle {
            height: 10px;
            width: 10px;
            margin: 5px;
            background-color: #B8EAFF;
            border-radius: 50%;
            display: inline-block;
            cursor: pointer;
        }

        .circle.active {
            background-color: #19A2D9;
            width: 30px;
            border-radius: 8px;
        }
    }
}

@media screen and (max-width: 1000px){
    .slidee {
        padding: 10px 20px;
        .slideshow {
        height: 400px;

        .slides-container{
            .slide{
                .slideContainer{
                    
                    .slideContent{
                        padding: 10px;
                        h2{
                            font-size: 7vw;
                        }
                        p{
                            font-size: 2vw;
                        }
                        button{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    }
    
}

@media screen and (max-width: 500px){
    .slidee {
        padding: 5px 10px;
        .slideshow {
        height: 280px;
        border-radius: 30px;

        .slides-container{
            .slide{
                .slideContainer{
                    .cover2 {
                        display: block;
                        object-fit: cover;
                    }
                    .cover {
                        display: none;
                    }
                    .slideContent{
                        
                        h2{display: none;}
                        h3{
                            display: block;
                            color: #FFF;
                            font-size: 9vw;
                            text-align: center;
                        }
                        p{
                            margin-top: 10px;
                            font-size: 4vw;
                        }
                        button{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    }
    
}

