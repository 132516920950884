.navbar {
    position: sticky;
    display: flex;
    width: 100%;
    top: 0;
    background: #FFFFFF;
    z-index: 10000;

    .navbarContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 30px;
        width: 100%;

        .logo {
            width: 120px;
        }

        .sections {
            display: flex;

            .section {
                display: flex;
                margin-left: 15px;
                cursor: pointer;
                border-radius: 50px;
                font-size: 16px;
                font-weight: 400;

                a {
                    text-decoration: none;
                    color: #000;

                    &:hover {
                        color: #FFF;
                    }
                }

                padding: 10px;
                transition: 300ms;

                &:hover {
                    background-color: #19A2D9;

                    a {
                        color: #FFF;
                    }

                }
            }

            .lang {
                margin-left: 25px;
                display: flex;
                gap: 5px;
                font-size: 15px;
                align-items: center;
                cursor: pointer;
                color: #5b5b5b;
                &:hover{
                    color: #19A2D9;
                }

                .ico{
                    font-size: 24px;
                }
            }
        }

        .hamburger {
            display: none;

        }


    }

    .hamburger {
        display: none;
    }


}

.navbar.scrolled {
    -webkit-box-shadow: -300px -3px 45px 21px rgba(0, 0, 0, 0.159);
    -moz-box-shadow: -300px -3px 45px 21px rgba(0, 0, 0, 0.159);
    box-shadow: 1px 1px 45px 5px rgba(0, 0, 0, 0.117);
}

@media screen and (max-width: 1200px) {
    .navbar {
        .navbarContent {

            .logo {
                width: 120px;
            }

            .sections {
                .section {
                    margin-left: 15px;
                    font-size: 16px;

                    padding: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .navbar {
        align-items: center;
        position: sticky;
        z-index: 1000;

        .navbarContent {
            padding: 10px 30px;

            .logo {
                width: 120px;
            }

            .sections {
                display: none;
            }
        }

        .hamburger {
            display: flex;
            color: #19A2D9;
            margin-right: 15px;
            z-index: 1100;
        }

        .menu {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            width: 65%;
            background-color: #ffffffcb;
            backdrop-filter: blur(8px);
            z-index: 1000;
            -webkit-box-shadow: -300px -3px 45px 21px rgba(0, 0, 0, 0.159);
            -moz-box-shadow: -300px -3px 45px 21px rgba(0, 0, 0, 0.159);
            box-shadow: -300px -3px 45px 21px rgba(0, 0, 0, 0.159);
            justify-content: right;

            .sections {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: right;
                padding: 100px 30px;

                .section {
                    margin-bottom: 5px;
                    cursor: pointer;
                    border-radius: 50px;
                    font-size: 20px;
                    font-weight: 400;

                    padding: 18px;
                    transition: 300ms;

                    &:hover {
                        background-color: #19A2D9;
                        color: #FFF;
                    }
                }

                .lang{
                    display: flex;
                    padding: 18px;
                    transition: 300ms;
                    gap: 15px;
                    cursor: pointer;

                    &:hover{
                        color: #19A2D9;
                    }
    
                    .ico{
                        font-size: 24px;
                    }
                }
            }
        }
    }
}