.gallary {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    .sectionOne {
        display: flex;
        flex-direction: column;
        justify-content: right;
        margin-top: 40px;
        text-align: right;

        h2 {
            font-size: 18px;
            font-weight: 400;
            color: #000;
        }

        .images {
            width: 100%;
            margin-top: 10px;
            display: flex;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            cursor: pointer;

            .swiper_container {
                width: 90%;
                padding: 2rem 0;
                position: relative;
                border-radius: 12px;

                .swiper-slide {
                    width: 400px;
                    height: 400px;
                    position: relative;
                    border-radius: 12px;

                    img {
                        width: 400px;
                        height: 400px;
                        object-fit: cover;
                        cursor: pointer;
                        border-radius: 12px;
                    }

                    .vidCon {
                        video {
                            width: 400px;
                            height: 400px;
                            object-fit: cover;
                            cursor: pointer;
                            border-radius: 12px;
                            top: 0;
                            left: 0;
                            position: relative;


                        }
                    }

                    .playIcon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 80px;
                        height: 80px;
                        color: #19A2D9;
                       
                    }

                }


            }
        }
    }

    .full {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        background-color: rgb(0, 0, 0, 0.9);
        width: 100%;
        height: 100%;
        text-align: center;

        .fullImageImg {
            height: 90%;
            /* Sets the maximum height to 80% of the container */
            width: 80%;
            /* Sets the maximum width to 80% of the container */
            object-fit: contain;
            margin: 10px;

        }
    }


}

@media screen and (max-width: 500px){
    .gallary {
        padding: 10px 15px;
    
        .sectionOne {
            align-items: center;
    
    
            .images {
                width: 100%;
                cursor: pointer;
    
                .swiper_container {
                    width: 100%;
                    padding: 2rem 0;
    
                    .swiper-slide {
                        width: 70%;
                        height: 250px;
                        position: relative;
                        border-radius: 12px;
    
                        img {
                            width: 100%;
                            height: 250px;
                            cursor: pointer;
                            border-radius: 12px;
                        }
    
                        .vidCon {
                            video {
                                width: 100%;
                                height: 250px;
                            }
                        }
    
                    }
    
    
                }
            }
        }
    
        .full {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000;
            background-color: rgb(0, 0, 0, 0.9);
            width: 100%;
            height: 100%;
            text-align: center;
    
            .fullImageImg {
                height: 90%;
                /* Sets the maximum height to 80% of the container */
                width: 80%;
                /* Sets the maximum width to 80% of the container */
                object-fit: contain;
                margin: 10px;
    
            }
        }
    
    
    }
}