.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .lineSection {
        border-top: 2px solid #26b1ec90;
        height: 0;
        width: 30%;
        margin-bottom: 20px;
    }

    h3{
        color: #19A2D9;
        font-weight: 500;
        font-size: 28px;
    }
}

@media screen and (max-width: 1000px){
    .section {
        .lineSection {
            width: 40%;
            margin-bottom: 20px;
        }
    
        h3{
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 500px){
    .section {
        .lineSection {
            width: 60%;
            margin-bottom: 15px;
        }
    
        h3{
            font-size: 20px;
        }
    }
}