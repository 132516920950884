.location {
    margin-top: 50px;
    padding: 20px;
    margin-bottom: 120px;
    

    .locationContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .locations {
            margin-top: 50px;
            margin-bottom: 40px;
            display: flex;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: center;
            justify-content: center;
            
            gap: 8vw;

            a{
                text-decoration: none;
                color: #000;
            }
            

            .loaction{
                display: flex;
                gap: 5px;
                cursor: pointer;

                p{
                    font-size: 18px;

                    &:hover{
                        color: #26B0EC;
                    }
                }
                
                .ico{
                    color: #26B0EC;
                }
            }
        }

        .map{
            width: 90%;
            height: 400px;
            border-radius: 12px;
            

            .mapview{
                border-radius: 12px;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .location {
        
    
        .locationContainer {
            width: 100%;
    
            .locations {
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-content: right;
                gap: 8vw;
                text-align: right;
            }
    
            .map{
                width: 100%;
            }
        }
    }
    
}